import {getTriggers, getConditions, getActions} from './helpers';

import TriggerDropdown from "../Dropdowns/TriggerDropdown.vue";
import ConditionOperatorsDropdown from "../Dropdowns/ConditionOperatorsDropdown.vue";
import ActionsDropdown from "../Dropdowns/ActionsDropdown.vue";

import HttpClient from "@/helpers/http.client";
const http = new HttpClient();

import AutomationService from "@/services/automation.service";
const service = new AutomationService(http);

export default {
  name: "AutomationsDetails",

  components: {
    TriggerDropdown,
    ConditionOperatorsDropdown,
    ActionsDropdown,
  },

  data() {
    return {
      isLoading: false,

      automation: {
        Name: '',
        Triggers: [],
        Conditions: [],
        Actions: [],
      },

      automationNameEdit: {
        show: false,
        value: '',
      },

      searchConditions: '',

      showAvailableTriggers: false,
      showAvailableConditions: false,
      showAvailableActions: false,

      showActionUserDropdown: false,
      showActionNotesDropdown: false,
      showActionActionPlanDropdown: false,

      showTriggerScheduleDropdown: false,

      searchAgents: '',
      agents: [],
    }
  },

  computed: {
    isCreateMode() {
      return this.$route.name === 'AutomationsCreate';
    },

    isEditMode() {
      return this.$route.name === 'AutomationsDetails';
    },

    isAutomationInvalid() {
      return !(this.automation.Name.length
          && this.automation.Triggers.length
          && this.automation.Actions.filter(el => el.Value).length
      );
    },


    triggers() {
      const triggers = getTriggers();

      if (this.isCreateMode) {
        return triggers;

      } else if (this.isEditMode) {

        this.automation.Triggers.forEach(currentTrigger => {
          triggers.map(trigger => {
            if (currentTrigger.Name === trigger.Name) {
              trigger.isSelected = currentTrigger.isSelected
            }
            return trigger
          })
        })

        return triggers
      }
    },

    conditions() {
      const conditions = getConditions();

      if (this.searchConditions) {
        return conditions.filter(condition => {
          return this.searchConditions
              .toLowerCase()
              .split(' ')
              .every(searchTerm =>
                  condition.Name.toLowerCase().includes(searchTerm)
              )
        })
      }

      return conditions;
    },

    actions() {
      const actions = getActions();

      if (this.isCreateMode) {
        return actions;

      } else if (this.isEditMode) {

        this.automation.Actions.forEach(currentAction => {
          actions.map(action => {
            if (currentAction.Name === action.Name) {
              action.isSelected = currentAction.isSelected
            }
            return action
          })
        })

        return actions
      }
    },


    hasTriggersAdded() {
      return this.automation.Triggers.length > 0;
    },

    allTriggersAdded() {
      return this.automation.Triggers.length === this.triggers.length;
    },


    hasConditionsAdded() {
      return this.automation.Conditions.length > 0;
    },

    allConditionsAdded() {
      return this.automation.Conditions.length === this.conditions.length;
    },


    hasActionsAdded() {
      return this.automation.Actions.length > 0;
    },

    allActionsAdded() {
      return this.automation.Actions.length === this.actions.length;
    },


    showCompletedStep() {
      return this.automation.Triggers.length > 0 &&
          this.automation.Actions.length > 0;
    },
  },

  async mounted() {
    if (this.isEditMode) {
      await this.getAutomation();
    }
  },

  methods: {
    async createAutomation() {
      this.isLoading = true;

      const res = await service.create(this.automation);
      if (res && !res.error) {
        this.$notify({type: 'success', message: this.$t('create_success')});
        await this.$router.push({name: 'AutomationsList'});

      } else {
        this.$notify({type: 'error', message: this.$t('create_error')});
      }

      this.isLoading = false;
    },

    async editAutomation() {
      this.isLoading = true;

      const res = await service.edit(this.automation);
      if (res && !res.error) {
        this.$notify({type: 'success', message: this.$t('edit_success')});
        await this.$router.push({name: 'AutomationsList'});

      } else {
        this.$notify({type: 'error', message: this.$t('edit_error')});
      }

      this.isLoading = false;
    },

    async getAutomation() {
      const automationId = this.$route.params.id;

      const res = await service.get(automationId);
      if (res && !res.error) {
        this.automation = res.data.automation;

        this.conditions.forEach(condition => {
          this.automation.Conditions.forEach(automationCondition => {
            if (automationCondition.Name === condition.Name) {
              condition.isSelected = true;
            }
          });
        });
      }
    },

    openAutomationName() {
      this.automationNameEdit.value = this.automation.Name;
      this.automationNameEdit.show = true;
    },

    editAutomationName() {
      this.automation.Name = this.automationNameEdit.value;
      this.automationNameEdit.show = false;
      this.automationNameEdit.value = '';
    },

    closeAutomationName() {
      this.automationNameEdit.show = false;
      this.automationNameEdit.value = '';
    },


    addTrigger(index) {
      const trigger = this.triggers[index];
      if (trigger.isSelected) {
        return;
      }

      this.automation.Triggers.push(trigger);
      trigger.isSelected = true;

      this[`showTrigger${trigger.Name}Dropdown`] = true;
      this.showAvailableTriggers = false;
    },

    removeTrigger(index) {
      const automationTrigger = this.automation.Triggers[index];

      const automationTriggerIndex = this.triggers.findIndex(trigger => trigger.Name === automationTrigger.Name);
      if (automationTriggerIndex > -1) {
        this.triggers[automationTriggerIndex].isSelected = false;
      }

      this.automation.Triggers.splice(index, 1);
    },

    toggleAvailableTriggers() {
      this.showAvailableTriggers = !this.showAvailableTriggers;
      this.showAvailableConditions = false;
      this.showAvailableActions = false;

      this.closeConditionsOperators();
      this.closeActionDropdowns();
    },

    onTriggerClick(trigger) {
      if (trigger.Name === 'Schedule') {
        this.showTriggerScheduleDropdown = !this.showTriggerScheduleDropdown;
      }

      this.closeAvailableDropdowns();
      this.closeConditionsOperators();
    },

    showTriggerDropdown(trigger) {
      const triggerKeys = {
        NewLead: false,
        NewAppointment: false,
        Schedule: this[`showTrigger${trigger.Name}Dropdown`],
      }

      return triggerKeys[trigger.Name];
    },

    onSelectTriggerFromDropdown(trigger) {
      this.closeTriggerDropdowns();

      const automationTriggerIndex = this.automation.Triggers.findIndex(el => el.Name === trigger.Name);
      if (automationTriggerIndex > -1) {
        this.automation.Triggers[automationTriggerIndex].Value = trigger.Value;
      }
    },

    closeTriggerDropdowns() {
      this.showTriggerScheduleDropdown = false;
    },

    triggerDescription(trigger) {
      if (trigger.Values.length > 0) {
        const result = {
          NewLead: trigger.Description,
          Name: trigger.Description,
          Schedule: trigger.Values.map(value => value.Description).join(' or '),
        }

        return result[trigger.Name];
      }

      return trigger.Description;
    },


    addCondition(index) {
      const condition = this.conditions[index];
      if (condition.isSelected) {
        return;
      }

      this.automation.Conditions.push(condition);
      condition.isSelected = true;

      this.showAvailableConditions = false;
    },

    removeCondition(index) {
      const automationCondition = this.automation.Conditions[index];

      const automationConditionIndex = this.conditions.findIndex(action => action.Name === automationCondition.Name);
      if (automationConditionIndex > -1) {
        this.conditions[automationConditionIndex].isSelected = false;
      }

      this.automation.Conditions.splice(index, 1);
    },

    toggleAvailableConditions() {
      this.showAvailableConditions = !this.showAvailableConditions;
      this.showAvailableTriggers = false;
      this.showAvailableActions = false;

      this.closeActionDropdowns();
    },

    conditionName(condition) {
      const [selectedOperator] = condition.Operators.filter(el => el.Checked);

      if (selectedOperator?.Value?.length) {
        return {
          label: `${condition.Label} ${this.$t(selectedOperator.Name)} ${selectedOperator.Value || ''}`,
          showTooltip: selectedOperator.Value.length > 7
        }

      } else if (selectedOperator?.Values?.length) {
        return {
          label: `${condition.Label} ${this.$t(selectedOperator.Name)} ${selectedOperator.Values.join(', ')}`,
          showTooltip: selectedOperator.Values.length > 1 || selectedOperator.Values[0]?.length > 14
        }

      } else {
        return {
          label: `${condition.Label} ${this.$t(selectedOperator?.Name)}`
        }
      }
    },


    toggleConditionOperators(condition) {
      if (condition.showOperators) {
        condition.showOperators = false;

      } else {
        this.automation.Conditions.map(el => el.showOperators = false);
        condition.showOperators = true;
      }

      this.closeActionDropdowns();
      this.closeAvailableDropdowns();
    },

    closeConditionOperators(condition) {
      condition.showOperators = false;
    },

    closeConditionsOperators() {
      this.automation.Conditions.map(el => el.showOperators = false);
    },

    onConditionOperatorChecked(condition, operator) {
      condition.Operators.map(el => el.Checked = false);
      operator.Checked = true;

      if (operator.isCombobox) {
        if (!operator.Values?.length) {
          operator.showDropdown = true;
        }
      }
    },

    onSelectOperatorFromDropdown(operator) {
      operator.showDropdown = false;
    },

    toggleConditionOperatorDropdown(condition, operator) {
      if (operator.showDropdown) {
        operator.showDropdown = false;

      } else {
        condition.Operators.map(el => el.showDropdown = false);
        operator.showDropdown = true;
      }
    },

    removeConditionOperatorItem(operator, itemIndex) {
      operator.Values.splice(itemIndex, 1);
    },


    addAction(index) {
      const action = this.actions[index];
      if (action.isSelected) {
        return;
      }

      this.automation.Actions.push(action);
      action.isSelected = true;

      this.showAvailableActions = false;
      this[`showAction${action.Name}Dropdown`] = true;
    },

    removeAction(index) {
      const automationAction = this.automation.Actions[index];

      const automationActionIndex = this.actions.findIndex(action => action.Name === automationAction.Name);
      if (automationActionIndex > -1) {
        this.actions[automationActionIndex].isSelected = false;
      }

      this.automation.Actions.splice(index, 1);

      this.closeActionDropdowns();
    },

    toggleAvailableActions() {
      this.showAvailableActions = !this.showAvailableActions;
      this.showAvailableTriggers = false;
      this.showAvailableConditions = false;

      this.closeConditionsOperators();
      this.closeActionDropdowns();
    },

    closeAvailableDropdowns() {
      this.showAvailableActions = false;
      this.showAvailableTriggers = false;
      this.showAvailableConditions = false;
    },

    showActionDropdown(action) {
      return this[`showAction${action.Name}Dropdown`];
    },

    closeActionDropdowns() {
      this.showActionUserDropdown = false;
      this.showActionNotesDropdown = false;
      this.showActionActionPlanDropdown = false;
    },

    onActionClick(action) {
      if (action.Name === 'User') {

        this.showActionUserDropdown = !this.showActionUserDropdown;
        this.showActionNotesDropdown = false;
        this.showActionActionPlanDropdown = false;

      } else if (action.Name === 'Notes') {

        this.showActionUserDropdown = false;
        this.showActionNotesDropdown = !this.showActionNotesDropdown;
        this.showActionActionPlanDropdown = false;

      } else {

        this.showActionUserDropdown = false;
        this.showActionNotesDropdown = false;
        this.showActionActionPlanDropdown = !this.showActionActionPlanDropdown;

      }

      this.closeAvailableDropdowns();
      this.closeConditionsOperators();
    },

    onSelectActionFromDropdown(action) {
      this.closeActionDropdowns();

      const automationActionIndex = this.automation.Actions.findIndex(el => el.Name === action.Name);
      if (automationActionIndex > -1) {
        this.automation.Actions[automationActionIndex].Value = action.Value;
      }
    },

    actionDescription(action) {
      if (action.Value) {
        const result = {
          User: `Assign <span style="font-weight: bold;color: #234A87">${action.Value.FirstName || ''} ${action.Value.LastName || ''}</span>`,
          ActionPlan: `Start <span style="font-weight: bold;color: #234A87">${action.Value.Name}</span>`,
          Notes: action.Description,
        }

        return result[action.Name];
      }

      return action.Description;
    }
  },
};