<template>
  <span class="dropdown">
    <div class="dropdown__container" v-if="visible">
      <div class="dropdown__body">

        <template v-if="type === 'Schedule'">
          <div class="schedule-available" v-if="showAvailableSchedules">
            {{ $t('when') }}
            <ComboBox
                v-model="scheduleValue.Type"
                :text="$t('choose_date')"
                :options="scheduleTypes"
                valueText="label"
                valueKey="value"
                minWidth="175px"
            />
          {{ $t('is_in') }}
          <TextBox v-model="scheduleValue.isInDays" width="50px"/>
          {{ $t('days') }}
          </div>

          <div class="schedule-selected" v-else-if="localTrigger.Values.length">
            <div class="schedule-selected__item" v-for="(value, index) in localTrigger.Values" :key="index">
              <span>{{ value.Description }}</span>
              <span class="ez-icon-clear" @click="removeSchedule(index)"/>
            </div>

            <div class="schedule-selected__add">
              <i class="ez-icon-add" @click="showAvailableSchedules = true"/>
            </div>
          </div>
        </template>

      </div>

      <div class="dropdown__footer">
        <Button type="cancel" :text="$t('cancel')" height="0" @click="closeDropdown"/>
        <Button type="primary" :text="$t('done')" height="0" @click="addSchedule"/>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  name: "AutomationTriggerDropdown",

  props: {
    type: {type: String, enum: ['Schedule']},
    trigger: Object,
    visible: {type: Boolean, default: false},
    onSelect: Function,
    onClose: Function,
  },

  data() {
    return {
      localTrigger: {
        Values: [],
      },

      showAvailableSchedules: false,
      scheduleTypes: [{label: this.$t('birthday'), value: 'birthday'}],
      scheduleValue: {
        Type: '',
        Date: '',
        isInDays: 0,
        Description: '',
      }
    }
  },

  async mounted() {
    if (this.trigger) {
      this.localTrigger = {...this.trigger};
      this.showAvailableSchedules = this.localTrigger.Values.length === 0;
    }
  },

  methods: {
    addSchedule() {
      if (this.showAvailableSchedules) {
        if (this.scheduleValue.Type.length > 0 && this.scheduleValue.isInDays >= 0) {
          this.scheduleValue.Description = this._scheduleDescription();

          const index = this.localTrigger.Values.findIndex(v => v.Type === this.scheduleValue.Type);
          if (index >= 0) {
            this.localTrigger.Values[index] = this.scheduleValue;
          } else {
            this.localTrigger.Values.push(this.scheduleValue);
          }

          this._resetScheduleValues();
          this.showAvailableSchedules = false;
          this.onSelect(this.localTrigger);
        }
      } else {
        this.closeDropdown();
      }
    },

    removeSchedule(index) {
      this.localTrigger.Values.splice(index, 1);
      this.showAvailableSchedules = this.localTrigger.Values.length === 0;
    },

    closeDropdown() {
      if (this.onClose) {
        this._resetScheduleValues();
        this.onClose();
      }
    },

    _resetScheduleValues() {
      this.scheduleValue = {
        Type: '',
        Date: '',
        isInDays: 0,
        Description: '',
      };
    },

    _scheduleDescription() {
      let daysDescription = '';

      const inDays = parseInt(this.scheduleValue.isInDays);
      if (inDays === 0) {
        daysDescription = this.$t('is_today');

      } else if (inDays === 1) {
        daysDescription = this.$t('is_tomorrow');

      } else {
        daysDescription = `${this.$t('is_in')} ${this.scheduleValue.isInDays} ${this.$t('days')}`;
      }

      return `${this.scheduleValue.Type.charAt(0).toUpperCase()}${this.scheduleValue.Type.slice(1)} ${daysDescription}`;
    }
  },
}
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  cursor: default;

  &__container {
    position: absolute;
    background: color(bg-white);
    border-radius: .3rem;
    box-shadow: $main-shadow;
    border: 1px solid color(border);
    margin-top: .9rem;
    left: calc(50% - 186px);
    width: 372px;

    &:after {
      position: absolute;
      content: '';
      background: color(bg-white);
      border-left: 1px solid color(border);
      border-top: 1px solid color(border);
      transform: rotate(45deg);
      top: -.54rem;
      left: calc(50% - 8px);
      height: 15px;
      width: 15px;
      z-index: 0;
    }
  }

  &__body {
    padding: .8rem;

    .schedule {
      &-available {
        display: flex;
        align-items: center;
        gap: .5rem;
        font-size: .8rem;
        white-space: nowrap;
      }

      &-selected {
        display: flex;
        align-items: center;
        gap: .5rem;

        &__item {
          font-size: .8rem;
          display: inline-flex;
          align-items: center;
          gap: .5rem;
          padding: .4rem .8rem;
          background: color(bg-fill);
          border-radius: 13px;

          .ez-icon-clear {
            cursor: pointer;
            font-size: 1.1rem;
            color: color(text-light);
          }
        }

        &__add {
          cursor: pointer;
          font-size: 1.1rem;
          background: color(bg-primary);
          border-radius: 50%;
          height: 1.5rem;
          width: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            color: color(bg-white);
          }
        }
      }
    }
  }

  &__footer {
    margin-top: .8rem;
    background: color(bg-fill);
    padding: .5rem;
    display: flex;
    justify-content: right;
    gap: .5rem;
  }
}
</style>

<i18n>
{
  "en": {
    "when": "When",
    "choose_date": "Choose date",
    "is_in": "is in",
    "is_today": "is today",
    "is_tomorrow": "is tomorrow",
    "days": "days",
    "done": "Done",
    "cancel": "Cancel",
    "birthday": "Birthday"
  },
  "es": {
    "when": "Cuando",
    "choose_date": "Elegir fecha",
    "is_in": "es en",
    "is_today": "es hoy",
    "is_tomorrow": "es mañana",
    "days": "días",
    "done": "Hecho",
    "cancel": "Cancelar",
    "birthday": "Cumpleaños"
  }
}
</i18n>