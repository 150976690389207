<template>
  <span class="dropdown">
    <div class="dropdown__container" :class="{'note': type === 'Notes'}" v-if="visible">
      <div class="dropdown__body">

        <template v-if="type === 'User'">
          <div class="items">
            <div class="items__search">
              <TextBox :placeholder="$t('search_agent')" fSize="1rem" bg="#fff" v-model="searchAgent"/>
            </div>
            <ul class="items__container ez-slim-scroll">
              <li class="items__item" v-for="(agent, index) in agentss" @click="selectAgent(agent)" :key="index">
                {{ agent.User.FirstName }} {{ agent.User.LastName }}
              </li>
            </ul>
          </div>
        </template>

        <template v-if="type === 'Notes'">
          <div class="notes">
              <div class="notes__body">
                <div class="notes__title">{{ $t('note_title') }}</div>
                 <Textarea v-model="note"/>
              </div>
              <div class="notes__footer">
                <Button type="secondary" :text="$t('done')" height="0" @click="addNote"/>
              </div>
          </div>
        </template>

        <template v-if="type === 'ActionPlan'">
          <div class="items">
            <div class="items__search">
              <TextBox :placeholder="$t('search_action_plans')" fSize="1rem" bg="#fff" v-model="searchAgent"/>
            </div>
            <ul class="items__container ez-slim-scroll">
              <li class="items__item" v-for="(actionPlan, index) in actionPlanss" :key="index"
                  @click="selectActionPlan(actionPlan)">
                {{ actionPlan.Name }} <span class="item-info">{{ actionPlan.Steps.length }} {{ $t('steps') }}</span>
              </li>
            </ul>
          </div>
        </template>

      </div>
    </div>
  </span>
</template>

<script>
import HttpClient from "@/helpers/http.client";
const http = new HttpClient();

import ActionPlanService from "@/services/action.plan.service";
import AgentService from '@/services/agent.service';

const agentService = new AgentService(http),
    actionPlanService = new ActionPlanService(http)

export default {
  name: "AutomationActionDropdown",

  props: {
    type: {type: String, enum: ['User', 'Notes', 'ActionPlan']},
    action: Object,
    visible: {type: Boolean, default: false},
    onSelect: Function,
    onClose: Function,
  },

  data() {
    return {
      searchAgent: '',
      agents: [],

      searchActionPlan: '',
      actionPlans: [],

      note: ''
    }
  },

  computed: {
    agentss() {
      if (this.searchAgent) {
        return this.agents.filter(agent => {
          return this.searchAgent
              .toLowerCase()
              .split(' ')
              .every(searchAgent => {
                return `${agent.User.FirstName} ${agent.User.LastName}`
                    .toLowerCase().includes(searchAgent);
              });
        });
      }

      return this.agents;
    },

    actionPlanss() {
      if (this.searchActionPlan) {
        return this.actionPlans.filter(actionPlan => {
          return this.searchActionPlan
              .toLowerCase()
              .split(' ')
              .every(searchActionPlan => {
                return actionPlan.toLowerCase().includes(searchActionPlan);
              });
        });
      }

      return this.actionPlans;
    },
  },

  async mounted() {
    await this.list();
  },

  methods: {
    async list() {
      try {
        const functions = {
          User: () => this.listAgents(),
          ActionPlan: () => this.listActionPlans(),
          Notes: () => [],
          Sources: () => () => this.listSources(),
        }

        await functions[this.type]();

      } catch (e) {
        console.error('AutomationDropdown - Required type prop');
      }
    },

    async listAgents() {
      const res = await agentService.list(100, 0);
      if (res && !res.error) {
        this.agents = res.data.agents;
      }
    },

    async listActionPlans() {
      const res = await actionPlanService.list(100, 0);
      if (res && !res.error) {
        this.actionPlans = res.data.actionPlans;
      }
    },

    selectAgent({User}) {
      if (this.onSelect) {
        const action = {...this.action};
        action.Value = User;

        this.onSelect(action);
      }
    },

    selectActionPlan(actionPlan) {
      if (this.onSelect) {
        const action = {...this.action};
        action.Value = actionPlan;

        this.onSelect(action);
      }
    },

    addNote() {
      if (this.onSelect) {
        const action = {...this.action};
        action.Value = this.note;

        this.onSelect(action);
      }
    },
  },
}
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;

  &__container {
    position: absolute;
    background: color(bg-white);
    border-radius: .3rem;
    box-shadow: $main-shadow;
    border: 1px solid color(border);
    margin-top: .9rem;
    left: calc(50% - 150px);
    width: 300px;

    &:after {
      position: absolute;
      content: '';
      background: color(bg-fill);
      border-left: 1px solid color(border);
      border-top: 1px solid color(border);
      transform: rotate(45deg);
      top: -.54rem;
      left: calc(50% - 8px);
      height: 15px;
      width: 15px;
      z-index: 0;
    }

    &.note {

      &:after {
        background: color(bg-white);
      }
    }
  }

  &__body {
    position: relative;
    z-index: 1;

    .items {

      &__search {
        background: color(bg-fill);
        padding: .5rem;
      }

      &__container {
        list-style: none;
        overflow: auto;
        //max-height: 200px;
        height: 200px;
      }

      &__item {
        display: flex;
        align-items: center;
        gap: .5rem;
        padding: 0 1.25rem;
        height: 40px;
        cursor: pointer;
        white-space: nowrap;
        font-size: .8rem;
        color: color(bg-gray-dark);

        .item-info {
          color: color(text-gray);
          font-size: .7rem;
        }

        &:hover {
          background: color(bg-primary-light);
        }
      }
    }

    .notes {
      cursor: default;

      &__body {
        padding: .8rem;
      }

      &__title {
        margin-bottom: .5rem;
        font-size: .9rem;
        color: color(bg-gray-dark);
      }

      &__footer {
        background: color(bg-fill);
        padding: .5rem;
        display: flex;
        justify-content: right;
      }
    }

    &__footer {
      margin-top: .8rem;
      background: color(bg-fill);
      padding: .5rem;
      display: flex;
      justify-content: right;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "search_agent": "Search agent",
    "search_action_plans": "Search action plans",
    "note_title": "When this automation is triggered, add this note:",
    "done": "Done",
    "steps": "steps"
  },
  "es": {
    "search_agent": "Search agent",
    "search_action_plans": "Search action plans",
    "note_title": "When this automation is triggered, add this note:",
    "done": "Done",
    "steps": "steps"
  }
}
</i18n>