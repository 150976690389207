<template>
  <span class="dropdown">
    <div class="dropdown__container" v-if="visible">
      <div class="dropdown__body">

        <template v-if="type === 'Source'">
          <div class="items">
            <div class="items__search">
              <TextBox :placeholder="$t('search_source')" fSize="1rem" bg="#fff" v-model="searchItems"/>
            </div>
            <ul class="items__container ez-slim-scroll">
              <li class="items__item" v-for="(item, index) in itemss" @click="selectSource(item)" :key="index">
                {{ item.Name }}
              </li>
            </ul>
          </div>
        </template>

      </div>
    </div>
  </span>
</template>

<script>
import HttpClient from "@/helpers/http.client";
const http = new HttpClient();

import SourceService from '@/services/lead.source.service';
const sourceService = new SourceService(http);

export default {
  name: "AutomationConditionOperatorDropdown",

  props: {
    type: {type: String, enum: ['Source']},
    visible: {type: Boolean, default: false},
    operator: Object,
    onSelect: Function,
    onClose: Function,
  },

  data() {
    return {
      searchItems: '',
      items: [],
    }
  },

  computed: {
    itemss() {
      if (this.searchItems) {
        return this.items.filter(item => {
          return this.searchItems
              .toLowerCase()
              .split(' ')
              .every(searchItems => {
                return item.Name.toLowerCase().includes(searchItems);
              });
        });
      }

      return this.items;
    }
  },

  async mounted() {
    await this.list();
  },

  methods: {
    async list() {
      try {
        const functions = {
          Source: () => this.listSources(),
        }

        await functions[this.type]();

      } catch (e) {
        console.error('AutomationDropdown - Required type prop');
      }
    },

    async listSources() {
      const res = await sourceService.list(500, 0);
      if (res && !res.error) {
        this.items = res.data.sources;
      }
    },

    selectSource({Name}) {
      if (this.onSelect) {
        const operator = this.operator;

        const index = operator.Values.indexOf(Name)
        if (index <= -1) {
          operator.Values.unshift(Name);
        }

        this.onSelect(operator);
      }
    },
  },
}
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;

  &__container {
    position: absolute;
    background: color(bg-white);
    border-radius: .3rem;
    box-shadow: $main-shadow;
    border: 1px solid color(border);
    margin-top: .9rem;
    left: calc(50% - 150px);
    width: 300px;
    z-index: 99999;

    &:after {
      position: absolute;
      content: '';
      background: color(bg-fill);
      border-left: 1px solid color(border);
      border-top: 1px solid color(border);
      transform: rotate(45deg);
      top: -.54rem;
      left: calc(50% - 8px);
      height: 15px;
      width: 15px;
      z-index: 0;
    }

    &.note {

      &:after {
        background: color(bg-white);
      }
    }
  }

  &__body {
    position: relative;
    z-index: 1;

    .items {

      &__search {
        background: color(bg-fill);
        padding: .5rem;
      }

      &__container {
        list-style: none;
        overflow: auto;
        //max-height: 200px;
        height: 200px;
      }

      &__item {
        display: flex;
        align-items: center;
        gap: .5rem;
        padding: 0 1.25rem;
        height: 40px;
        cursor: pointer;
        white-space: nowrap;
        font-size: .8rem;
        color: color(bg-gray-dark);

        .item-info {
          color: color(text-gray);
          font-size: .7rem;
        }

        &:hover {
          background: color(bg-primary-light);
        }
      }
    }

    &__footer {
      margin-top: .8rem;
      background: color(bg-fill);
      padding: .5rem;
      display: flex;
      justify-content: right;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "search_source": "Search source",
    "done": "Done"
  },
  "es": {
    "search_source": "Search source",
    "done": "Done"
  }
}
</i18n>