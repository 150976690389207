export const getTriggers = () => [
  {
    Name: 'NewLead',
    Label: 'New Lead',
    Values: [],
    Description: 'New Lead Inquire',
    Icon: 'ez-icon-user',
    isSelected: false,
  }, {
    Name: 'NewAppointment',
    Label: 'New Appointment',
    Values: [],
    Description: 'New Appointment Created',
    Icon: 'ez-icon-appointment',
    isSelected: false,
  }, {
    Name: 'Schedule',
    Label: 'Schedule',
    Values: [],
    Description: 'Schedule a Date',
    Icon: 'ez-icon-calendar-month',
    isSelected: false,
  }
];

export const getConditions = () => [
  {
    Name: 'FirstName',
    Label: 'First Name',
    Operators: _getOperators('string'),
    Value: '',
    Icon: 'ez-icon-italic',
    isSelected: false,
    showOperators: true,
  }, {
    Name: 'LastName',
    Label: 'Last Name',
    Operators: _getOperators('string'),
    Value: '',
    Icon: 'ez-icon-italic',
    isSelected: false,
    showOperators: true,
  }, {
    Name: 'Phone',
    Label: 'Phone',
    Operators: _getOperators('phone'),
    Value: '',
    Icon: 'ez-icon-call',
    isSelected: false,
    showOperators: true,
  }, {
    Name: 'Email',
    Label: 'Email',
    Operators: _getOperators('email'),
    Value: '',
    Icon: 'ez-icon-email',
    isSelected: false,
    showOperators: true,
  }, {
    Name: 'Source',
    Label: 'Source',
    Operators: _getOperators('source'),
    Value: '',
    Icon: 'ez-icon-snap-to',
    isSelected: false,
    showOperators: true,
  },
];

export const getActions = () => [
  {
    Name: 'User',
    Label: 'Assign',
    Value: '',
    Description: 'Assign Agent',
    Icon: 'ez-icon-repeat',
    isSelected: false,
  }, {
    Name: 'Notes',
    Label: 'Add Note',
    Value: '',
    Description: 'Add Note',
    Icon: 'ez-icon-pin',
    isSelected: true,
  }, {
    Name: 'ActionPlan',
    Label: 'Action Plan',
    Value: '',
    Description: 'Execute Action Plan',
    Icon: 'ez-icon-play-circle-outlined',
    isSelected: false,
  },
];

const _getOperators = (type) => {
  const types = {
    string: [
      {
        Name: 'is_not_empty',
        Checked: true,
      }, {
        Name: 'contains',
        Checked: false,
        isInput: true,
        Value: '',
      }, {
        Name: 'is_empty',
        Checked: false,
      }
    ],

    phone: [
      {
        Name: 'is_not_empty',
        Checked: true,
      }, {
        Name: 'starts_with',
        Checked: false,
        isInput: true,
        Value: '',
      }, {
        Name: 'is_empty',
        Checked: false,
      }
    ],

    email: [
      {
        Name: 'is_not_empty',
        Checked: true,
      }, {
        Name: 'contains',
        Checked: false,
        isInput: true,
        Value: '',
      }, {
        Name: 'does_not_contains',
        Checked: false,
        isInput: true,
        Value: '',
      }, {
        Name: 'is_empty',
        Checked: false,
      }
    ],

    source: [
      {
        Name: 'include',
        Checked: false,
        isCombobox: true,
        showDropdown: false,
        Values: [],
      },
      {
        Name: 'exclude',
        Checked: false,
        isCombobox: true,
        showDropdown: false,
        Values: [],
      },
    ],
  }

  return types[type] || [];
};